import React from "react";
import { Grid, Typography, Box, Button, Link } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import PhoneIcon from "@mui/icons-material/Phone";
import HomeIcon from "@mui/icons-material/Home";
import useStyles from "../styles/styles";

const Section = () => {
  const classes = useStyles();

  const sectionItems = [
    {
      id: 1,
      title: "Resources",
      url: "/resources",
      icon: (
        <GroupsIcon sx={{ fontSize: 100, color: "#284b63" }} color="#284b63" />
      ),
      sentence:
        "We all need a little help and support from time to time. We partner with other non-profits and groups in the KC area to provide resources.",
    },
    {
      id: 2,
      title: "Hotlines",
      url: "/hotlines",
      icon: (
        <PhoneIcon sx={{ fontSize: 100, color: "#284b63" }} color="#284b63" />
      ),
      sentence:
        "See our list of hotlines that may help you in whatever situation you find yourself in",
    },
    {
      id: 3,
      title: "Project VERGE",
      url: "/verge",
      icon: <HomeIcon sx={{ fontSize: 100, color: "#284b63" }} />,
      sentence:
        "An all encompassing pallet homes community will provide everything and more to help our houseless neighbors take their first step towards self-sustainability.",
    },
  ];
  return (
    <Box sx={{ flexGrow: 1, minHeight: "400px" }}>
      <Grid container className={classes.sectionGridContainer}>
        {sectionItems.map((item) => (
          <Grid
            item
            xs={12}
            md={3.5}
            minHeight={300}
            key={item.id}
            className={classes.sectionGridItem}
          >
            {item.icon}

            <Typography>{item.sentence}</Typography>
            <Link href={item.url} underline="none" key={item.url}>
              <Button style={{ color: "white", backgroundColor: "#3c6e71" }}>
                {item.title}
              </Button>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Section;
