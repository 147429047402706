import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  TextareaAutosize,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import emailjs from "emailjs-com";
import useStyles from "../styles/styles";

const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [subject, setSubject] = useState("GeneralInquiry");
  const [message, setMessage] = useState("");
  const classes = useStyles();

  const submitForm = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "gmail",
        "template_tu1nfnz",
        e.target,
        "user_XH1mUQ9TxnmlN44b40M27"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Box className={classes.formContainer}>
      <Typography variant="h4" className={classes.formHeading}>
        Contact Us
      </Typography>
      <Box
        className={classes.form}
        component="form"
        noValidate
        autoComplete="off"
      >
        <TextField
          label="Full Name"
          variant="outlined"
          fullWidth
          className={classes.inputField}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          className={classes.inputField}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputLabel id="demo-simple-select-label">Subject</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          fullWidth
          id="demo-simple-select"
          value={subject}
          label="Subject"
          className={classes.inputField}
          onChange={(e) => setSubject(e.target.value)}
        >
          <MenuItem value="GeneralInquiry">General Inquiry</MenuItem>
          <MenuItem value="Volunteering">Volunteering</MenuItem>
          <MenuItem value="Donations">Donations</MenuItem>
          <MenuItem value="parkCleanup">Clean Up Sites</MenuItem>
          <MenuItem value="Verge">Verge</MenuItem>
        </Select>

        <TextareaAutosize
          aria-label="minimum height"
          minRows={6}
          placeholder="Enter a message"
          className={classes.textArea}
          spellCheck
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <Button
          variant="contained"
          type="submit"
          sx={{ width: "200px", fontSize: "16px", backgroundColor: "#3c6e71" }}
          onClick={submitForm}
        >
          Send Email
        </Button>
      </Box>
    </Box>
  );
};

export default ContactUs;
