import React, { Component } from "react";
import axios from "axios";
import "../css/resources.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { defaultColDef, resourceColumns } from "../utils/resourceColumns";
// import firebase from "../utils/firebase.js";
import { Grid, Typography, Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
export default class Resources extends Component {
  state = {
    resources: [],
    height: 750,
    gridOptions: { columnDefs: resourceColumns, defaultColDef: defaultColDef },
  };
  componentDidMount() {
    // firebase.analytics().logEvent("resources_page");
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    const arr = [];
    axios
      .get("https://mergingkc-7b79b.firebaseio.com/Resources.json")
      .then((response) => {
        Object.keys(response.data).forEach((key) =>
          arr.push(response.data[key])
        );
        this.setState({ resources: arr });
      })
      .catch((error) => {
        this.setState({ error: true });
      });
  }
  resize() {
    if (window.innerHeight <= 900 && window.innerHeight > 750) {
      this.setState({ height: 600 });
    } else if (window.innerHeight <= 749 && window.innerHeight > 600) {
      this.setState({ height: 450 });
    } else if (window.innerHeight < 400) {
      this.setState({ height: 300 });
    } else {
      this.setState({ height: 750 });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    return (
      <>
        <Grid
          container
          spacing={11}
          style={{ marginTop: "1%" }}
          justifyContent="center"
        >
          <Grid item lg={10} xs={7}>
            <Typography variant="h4">Resources</Typography>
          </Grid>
          <Grid item xs={1}>
            <Button
              onClick={() => {
                this.state.gridOptions.api.exportDataAsCsv({
                  fileName: "resources.csv",
                });
              }}
            >
              <DownloadIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={12}
          justifyContent="center"
          style={{ paddingBottom: "1%" }}
        >
          <Grid item xs={11}>
            <div
              className="ag-theme-alpine"
              style={{
                height: this.state.height,
                width: "100%",
              }}
            >
              <AgGridReact
                rowData={this.state.resources}
                gridOptions={this.state.gridOptions}
                rowSelection="none"
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
