import React from "react";
import { Box } from "@mui/material";
import useStyles from "../styles/styles";
import FacebookIcon from "@mui/icons-material/Facebook";
const Footer = () => {
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }} className={classes.footerContainer}>
      <h5>Board Members</h5>
      <ul>
        <li>Houston DeFoe Co-Founder</li>
        <li>Shelby Frisby - Secretary and Co-Founder</li>
        <li>Duane Jordan - Member</li>
        <li>Kara Elliott - Tech Support</li>
      </ul>
      <h5>Copyright &copy; Merging KC 2020</h5>
      <a href="https://www.facebook.com/groups/203508274052004">
        <FacebookIcon sx={{ color: "#4267B2" }} />
      </a>
    </Box>
  );
};

export default Footer;
