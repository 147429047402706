export const hotlineColumns = [
  {
    headerName: "Organization",
    field: "organization",
    filter: "agTextColumnFilter",
    minWidth: 200,
  },
  {
    headerName: "Phone",
    field: "phone",
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "Web",
    field: "web",
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Type",
    field: "type",
    filter: "agTextColumnFilter",
    minWidth: 300,
  },
];

export const defaultColDef = {
  flex: 1,
  floatingFilter: true,
  sortable: true,
};
