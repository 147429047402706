import React, { Component } from "react";

import "../css/verge.css";
export default class Verge extends Component {
  render() {
    return (
      <>
        <div className="container-fluid parallax"></div>
        <div className="caption">
          <span className="border">Project VERGE</span>
        </div>
        <div
          style={{
            backgroundColor: "white",
            paddingTop: "1%",
            paddingLeft: "5%",
            paddingRight: "5%",
          }}
        >
          <h2 className="text-center">
            The First Ever Temporary Pallet Home Community To Kansas City
          </h2>
          <br />
          <p>
            A dynamic community deserves a dynamic solution. Our metropolitan's
            houseless population have layers of intersections as to why they
            have found themselves living on the streets, and Verge could be
            their turning point. An all encompassing pallet homes community will
            provide everything and more to help our houseless neighbors take
            their first step towards self-sustainability.
          </p>
        </div>
        <div className="bgimg-2"></div>
        <h2 style={{ color: "#6da4e3" }} className="text-center">
          "No one should go unsheltered when shelter can be built in a day. " -
          Pallet
        </h2>
        <br />
        <div
          style={{
            paddingLeft: "5%",
            paddingRight: "5%",
          }}
        >
          <p>
            <a href="https://palletshelter.com/" className="pallet">
              Pallet
            </a>{" "}
            is based out of Washington where 80% of their employees have
            experienced houselessness. They refer to themselves as a social
            purpose company dedicated to investing in people rather than profit.
          </p>
        </div>
        <div className="bgimg-3"></div>
        <div
          style={{
            backgroundColor: "white",
            paddingLeft: "5%",
            paddingRight: "5%",
          }}
        >
          <p style={{ padding: "1%" }}>
            In the years of COVID-19 specifically,{" "}
            <b className="bold">
              it’s been very evident that the longtime shelters and resources in
              Kansas City do not have the capabilities of addressing
              homelessness in all of it’s intricacies.
            </b>
            These preexisting organizations seemed to struggle with modernizing
            and adapting for various reasons as our homeless population
            continued to grow and diversify. Our community deserves more; a
            one-way operating system to fit everyone’s needs just isn’t
            realistic. <b>Verge was born from that realization.</b>
          </p>
          <p style={{ padding: "1%" }}>
            Like previously stated, dynamic situations need to be addressed with
            dynamic solutions. The common barriers and loopholes that the
            houseless community finds themselves in will all be resolved within
            this community.{" "}
            <b className="bold">
              The intersections of employment security, mental and emotional
              stability, financial independence, and so much more will all be
              addressed collaboratively to adequately assist each and every
              individual with their desired end result.
            </b>
          </p>
          <p style={{ padding: "1%" }}>
            There is no time limit per an individual’s graduation point with
            Verge{" "}
            <b className="bold">
              - each client will receive the same amount of care and support
              regardless of the time frame of their projected exit to their next
              chapter.
            </b>{" "}
            Whether that be a month, or eight months, we will meet every
            individual exactly where they are to aid them in establishing
            self-sufficiency.
          </p>
          <p style={{ padding: "1%" }}>
            Each pallet home provides locked windows, locked front door,
            insulation, two beds, electricity, heating, cooling, and storage
            space. There will also be an additional extension of facilities with
            showers, restrooms, and laundry services to access at any point.
          </p>
          <p style={{ padding: "1%" }}>
            The emphasis is that it’s potentially temporary -{" "}
            <b className="bold">
              this is not meant to be permanent housing or a permanent living
              arrangement.{" "}
            </b>
            Just like tents, these pallet homes will be temporary and will lead
            the residents to their next venture.{" "}
            <b className="bold">
              This community will be laid out very similarly to an actual
              neighborhood,
            </b>{" "}
            rather than one large facility structure. It will feature a garden
            and various community spaces for everyone to utilize.{" "}
          </p>
        </div>
        <div className="bgimg-4"></div>
        <div
          style={{
            backgroundColor: "white",
            paddingTop: "1%",

            paddingLeft: "5%",
            paddingRight: "5%",
          }}
        >
          <h2 className="text-center">VERGE OPERATIONS</h2>{" "}
          <p style={{ padding: "1%" }}>
            {" "}
            For the operational staff for Verge - we want to keep it simple
            That's why we have allocated salaries for Executive Director,
            Volunteer Coordinator, Senior Case Managers, and Support Case
            Managers while mapping out financial costs. Additional support will
            be provided by volunteers, community partners, and required
            volunteer hours by residents.
          </p>
          <p style={{ padding: "1%" }}>
            We are also planning regularly scheduled fundraising events,
            recruitment for donations on a monthly or annual basis, as well as
            launching sponsorship opportunities throughout the metropolitan. All
            of this work will be between the board, the Executive Director and
            other involved parties.
          </p>
          <p style={{ padding: "1%" }}>
            Our metropolitan has been eagerly awaiting the arrival of Verge for
            the last several months.The support from our community was
            overwhelming during the summer and it fuels us to make sure that
            this project follows through. We see so many opportunities to help
            engage with our houseless neighbors, and Verge is just the first
            step. This one of a kind pallet shelter village provides dignity,
            safety, and encouragement all in one centralized space. This is the
            pathway to permanant housing for those experiencing houselessness.
          </p>
        </div>
        <div></div>
      </>
    );
  }
}
