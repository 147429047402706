import "./App.css";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "./components/Header";
import Intro from "./components/Intro";
import Section from "./components/Section";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Resources from "./components/Resources";
import Hotlines from "./components/Hotlines";
import Verge from "./components/Verge";
const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Header />

          <Switch>
            <Route path="/verge">
              <Verge />
            </Route>
            <Route path="/hotlines">
              <Hotlines />
            </Route>
            <Route path="/resources">
              <Resources />
            </Route>
            <Route path="/">
              <Intro />
              <Section />
              <ContactUs />
            </Route>{" "}
          </Switch>
          <Footer />
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
